import { RECALL_CLASSIFICATIONS, RECALL_STATUS ,OUTPUT_DATE_FORMAT, PRODUCT_DISRUPTION_TYPE, ACTION_TYPE_LIST, ANTICIPATED_DURATION} from '../../../../../misc/commons'
import DocumentViewModel from '../../../../../components/part/shared/DocumentViewModel'
import recallServices from '../../../../../services/supplier/supplier-recall'
import { COMMUNICATION_TYPE } from '../../../../../misc/commons'
export default {
    props: {
        communicationId: {type: String, default: null},
        communicationType: {type: String, default: ''},
    },
    components: {
        DocumentViewModel
    },
    data() {
        return {
            recall_classifications: RECALL_CLASSIFICATIONS,
            anticipated_duration: ANTICIPATED_DURATION,
            recall_status: RECALL_STATUS,
            item: null,
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,
            labels: {},
            recall: COMMUNICATION_TYPE.RECALL,
            productDisruption: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            disruption: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            disruption_type: PRODUCT_DISRUPTION_TYPE,
            action_type: ACTION_TYPE_LIST,
            documents: []
        }
    },
    created() {
        this.labels={
            name: this.communicationType == this.recall ? 'Recall Name' : 'Supply Advisory Name',
            type: this.communicationType == this.recall ? 'Recall Type' : 'Type',
            date: this.communicationType == this.recall ? 'Recall Date' : 'Advisory Date',
            communicationUID: this.communicationType == this.recall ? 'Recall ID' : 'Supply Advisory ID',
            status: this.communicationType == this.recall ? 'Recall Status' : 'Status',
            riskToHealth: this.communicationType == this.recall? 'Risk to Health': 'Alternatives & Conservation Measures',
            categories: this.communicationType == this.recall? 'Recall Categories': 'Category',
            image: this.communicationType == this.recall? 'Recall Image': 'Supply Advisory Image',
            product: this.communicationType == this.recall? 'Recall Products': 'Affected Products',
        }
        this.getRecallById()
    },
    methods: {
        getRecallById() {
            recallServices.getRecallById(this.communicationId, this.communicationType).then(resp => {
                if (resp.error){
                    this.$router.push({ name: 'supp_Recall' })
                } else {
                    this.item = resp.data.d
                    this.documents = resp.data.d.documents
                    this.$emit('documentsUpdated', this.documents)
                    this.item.updates.forEach(s => {
                        s.disruptionUpdate = s.disruptionUpdate.replaceAll('ql-align-right', 'text-right').replaceAll('ql-align-center', 'text-center')
                            .replaceAll('ql-indent-1','indent-1').replaceAll('ql-indent-2','indent-2').replaceAll('ql-indent-3','indent-3')
                            .replaceAll('ql-indent-4','indent-4').replaceAll('ql-indent-5','indent-5').replaceAll('ql-indent-6','indent-6')
                            .replaceAll('ql-indent-7','indent-7').replaceAll('ql-indent-8','indent-8')
                    })
                }
                this.$emit('onReLoadCommunicationData')
            })
        },
        ChangeIdentifires(fields, field, event) {
            let rownumber = field.options.filter(x => x.value == event)[0].rowNumber
            let uniquefields = fields.filter(x => x.is_unique_identifier == true && x.field_name != field.field_name)
            for (let index = 0; index < uniquefields.length; index++) {
                let uniquefield = fields.filter(x => x.is_unique_identifier == true && x.field_name == uniquefields[index].field_name)[0]
                uniquefield.selected_value = uniquefield.options.filter(x => x.rowNumber == rownumber)[0].value
            }
        }
    }
}