import recallServices from '../../../../../services/supplier/supplier-recall'
import ActionType from '../../../../../components/part/provider/ActionType'
import { OUTPUT_DATE_FORMAT} from '../../../../../misc/commons'
import { COMMUNICATION_TYPE } from '../../../../../misc/commons'
import ReminderMailModel from './ReminderMailModel'
export default {
    props: {
        communicationId: {type: String, default: null},
        communicationType: {type: String, default: ''},
    },
    components: {
        ActionType,
        ReminderMailModel
    },
    data() {
        return {
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,
            communicationTypeForRecall: COMMUNICATION_TYPE.RECALL,
            viewBy: null,
            allOpenRows: [],
            allOpenactionListRows: [],
            providerList_Detailschild: [],
            actionList_DetailsChild: [],
            actionList: [],
            actionList_Details: [],
            selectedActionId: null,
            actionListFields: [
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true,
                },
                {
                    key: 'progress',
                    label: 'Progress',
                    class: 'text-center',
                    thStyle: { width: '60px' },
                    sortable: true,
                }
            ],
            actionList_Details_Recalls_Fields: [

                {
                    key: 'provider_name',
                    label: 'Provider',
                    sortable: true
                },
                {
                    key: 'account_type',
                    label: 'Account Type',
                    sortable: true,
                    class: 'text-center',
                    thStyle: { width: '125px' },
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true
                },
                {
                    key: 'modified_at',
                    label: 'Last Modified',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'User',
                    sortable: true,
                },
                {
                    key: 'isExpanded',
                    label: '',
                    sortable: false,
                }
            ],
            actionList_Details_Disruptions_Fields: [

                {
                    key: 'provider_name',
                    label: 'Provider',
                    sortable: true
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true
                },
                {
                    key: 'modified_at',
                    label: 'Last Modified',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'User',
                    sortable: true,
                },
                {
                    key: 'isExpanded',
                    label: '',
                    sortable: false,
                }
            ],
            actionList_Details_Recalls_FieldsChild: [

                {
                    key: 'actionDesc',
                    label: 'Description',
                    sortable: true
                },
                {
                    key: 'account_type',
                    label: 'Account Type',
                    sortable: true,
                    class: 'text-center',
                    thStyle: { width: '125px' },
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true
                },
                {
                    key: 'modified_at',
                    label: 'Last Modified',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'User',
                    sortable: true,
                },
            ],
            actionList_Details_Disruptions_FieldsChild: [

                {
                    key: 'actionDesc',
                    label: 'Description',
                    sortable: true
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true
                },
                {
                    key: 'modified_at',
                    label: 'Last Modified',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'User',
                    sortable: true,
                },
            ],
            providerList: [],
            allCompletedProviderList: false,
            providerList_Details: [],
            selectedProviderId: null,
            selectedProviderRecallId: null,
            remindermailModelKey: 0,
            providerListFields: [
                {
                    key: 'name',
                    label: 'Provider',
                    sortable: true
                },
                {
                    key: 'numberOfLocations',
                    label: '# of Locations',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false,
                    thStyle: { width: '60px' },
                },
                {
                    key: 'completed',
                    label: '',
                    class: 'text-center',
                    thStyle: { width: '60px' },
                    sortable: true,
                }
            ],
            providerList_Details_Fields: [
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true
                },
                {
                    key: 'modified_at',
                    label: 'Last Modified',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'User',
                    sortable: true,
                },
                {
                    key: 'isExpanded',
                    label: '',
                    sortable: false,
                }
            ],
            providerList_Details_Fieldschild: [
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true,
                    tdClass: 'DescriptioncolClass',
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true,
                    tdClass: 'responsecolClass',
                },
                {
                    key: 'modified_at',
                    label: 'Last Modified',
                    sortable: true,
                    tdClass: 'modifiedcolClass',
                },
                {
                    key: 'name',
                    label: 'User',
                    sortable: true,
                    tdClass: 'usercolClass',
                },
            ],

            PROVIDER_RECALL_STATUS: [
                { value: 'OPEN', text: 'In Progress', color: '#ffc107' },
                { value: 'COMPLETED', text: 'Completed', color: '#28a745' },
            ]
        }
    },
    created(){
        this.$emit('onReLoadCommunicationData')
    },
    mounted() {
        this.viewBy = 'Provider'
    },
    watch: {
        viewBy(val) {
            if (val == 'Action') {
                this.GetRecallAction()
            } else
            if (val == 'Provider') {
                this.GetRecallAction_ProviderListing()
            }
        }
    },
    methods: {
        handleRowClicked(item, index) {
            if(item.isCustomizedContent)
            {
                item.isExpanded=item.isCustomizedContent ?!item.isExpanded:item.isExpanded
            }
            if(item.isCustomizedContent)
            {
                this.allOpenRows.map((ele) => {
                    if (ele.id !== item.id && ele._showDetails) {
                        this.$set(ele, '_showDetails', !ele._showDetails)
                    }
                })
                this.allOpenRows = []
                this.$set(item, '_showDetails', !item._showDetails)
                this.allOpenRows.push(item)
            }
        },
        handleRowClickedActionList(item)
        {
            if(item.isCustomizedContent  && item.baseActionId==0)
            {
                item.isExpanded=item.isCustomizedContent ?!item.isExpanded:item.isExpanded
                this.allOpenactionListRows.map((ele) => {
                    if (ele.id !== item.id && ele._showDetails) {
                        this.$set(ele, '_showDetails', !ele._showDetails)
                    }
                })
                this.allOpenactionListRows = []
                this.$set(item, '_showDetails', !item._showDetails)
                this.allOpenactionListRows.push(item)
            }
        },
        SendReminderMail() {
            this.remindermailModel += 1
            this.$nextTick(() => {
                this.$refs.remindermailModel.forceRerenderView(
                    null,
                    this.communicationId,
                    'SendMail',
                    this.providerCommunicationId == undefined ? this.$route.params.providerCommunicationId : this.providerCommunicationId,
                    this.communicationType
                )
            })
        },
        rowTableProviderClick(item) {
            this.selectedProviderId = item.provider_id

            this.selectedProviderRecallId = item.providerCommunicationId
            this.GetRecallAction_ProviderListing_Detail()
        },
        rowTableActionClick(item) {
            this.selectedActionId = item.action_id
            this.GetRecallAction_Detail()
        },
        rowClassProvider(item, type) {
            if (!item || type !== 'row') return
            if (item.provider_id === this.selectedProviderId && item.providerCommunicationId === this.selectedProviderRecallId) return 'table-primary'
        },
        rowClassAction(item, type) {
            if (!item || type !== 'row') return
            if (item.action_id === this.selectedActionId) return 'table-primary'
        },
        GetRecallAction () {
            recallServices.GetRecallAction(this.communicationId).then(resp => {
                if (!resp.error) {
                    this.actionList = resp.data.d
                    if (this.actionList && this.actionList[0] && this.actionList[0].action_id){
                        this.selectedActionId = this.actionList[0].action_id
                        this.GetRecallAction_Detail()
                    }
                }
            })
        },
        GetRecallAction_Detail () {
            recallServices.GetRecallAction_Detail(this.communicationId, this.selectedActionId).then(resp => {
                if (!resp.error) {
                    this.actionList_Details = resp.data.d.filter(s=>s.baseActionId==0)
                    this.actionList_DetailsChild =resp.data.d.filter(s=>s.baseActionId>0)
                    this.actionList_Details.forEach(item => {
                        if(!item.isCustomizedContent)
                        {
                            if (item.data.valueSingleChoice == undefined && item.data.valueNumeric == undefined && item.data.valueMultiChoice == undefined && item.data.valueAcknowledge == undefined && item.data.valueYesNo == undefined && item.data.valueText == undefined && item.data.valueImage == undefined && item.data.valueDocument == undefined && item.data.valueCheckbox == undefined && item.type != 'Acknowledge') {
                                item.account_type = null
                            }
                        }
                        else
                        {
                            let isResponded = false
                            let child= this.actionList_DetailsChild.filter(s=>s.provider_id==item.provider_id && s.providerCommunicationId ==  item.providerCommunicationId)
                            child.forEach(childitem => {
                                if (childitem.data.valueSingleChoice == undefined && childitem.data.valueNumeric == undefined && childitem.data.valueMultiChoice == undefined && childitem.data.valueAcknowledge == undefined && childitem.data.valueYesNo == undefined && childitem.data.valueText == undefined && childitem.data.valueImage == undefined && childitem.data.valueDocument == undefined && childitem.data.valueCheckbox == undefined) {
                                    childitem.account_type = null
                                }
                                else
                                {
                                    isResponded = true
                                }
                            })
                            if(!isResponded && item.type != 'Acknowledge')
                            {
                                item.account_type = null
                            }
                        }
                    })
                }
            })
        },
        GetRecallAction_ProviderListing () {
            recallServices.GetRecallAction_ProviderListing(this.communicationId).then(resp => {
                if (!resp.error) {
                    this.providerList = resp.data.d
                    if (this.providerList && this.providerList[0] && this.providerList[0].provider_id){
                        this.selectedProviderId = this.providerList[0].provider_id

                        this.selectedProviderRecallId = this.providerList[0].providerCommunicationId
                        this.GetRecallAction_ProviderListing_Detail()
                    }
                    this.allCompletedProviderList = this.providerList.every(item => item.status === 'COMPLETED')
                }
            })
        },
        GetRecallAction_ProviderListing_Detail () {

            recallServices.GetRecallAction_ProviderListing_Detail(this.communicationId, this.selectedProviderId,this.selectedProviderRecallId).then(resp => {
                if (!resp.error) {
                    var actions=[]
                    resp.data.d.filter(s=>s.baseActionId==0).forEach((action) => {
                        if(action.isCustomizedContent==false)
                        {
                            actions.push(action)
                        }
                        else{
                            let clidlActions = resp.data.d.filter(x => x.baseActionId == action.action_id)
                            if(clidlActions.length>0)
                            {
                                actions.push(action)
                            }
                        }

                    })
                    this.providerList_Details = actions
                    this.providerList_Detailschild=resp.data.d.filter(s=>s.baseActionId>0)
                }
            })
        },
        exportproviderstausReport() {
            recallServices.getRecallById(this.communicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'supp_Recall' })
                } else {
                    recallServices.exportproviderstausReport(this.communicationId, resp.data.d.communicationUID)
                }
            })
        },
        downloadproviderstausReport() {
            recallServices.getRecallById(this.communicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'supp_Recall' })
                } else {
                    recallServices.downloadproviderstausReport(this.communicationId, resp.data.d.communicationUID)
                }
            })
        },
        exportproviderLocationstausReport(){
            recallServices.getRecallById(this.communicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'supp_Recall' })
                } else {
                    recallServices.exportproviderLocationstausReport(this.communicationId, resp.data.d.communicationUID)
                }
            })
        },
        downloadproviderLocationstausReport() {
            recallServices.getRecallById(this.communicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'supp_Recall' })
                } else {
                    recallServices.downloadproviderLocationstausReport(this.communicationId, resp.data.d.communicationUID)
                }
            })
        },

    }
}