import recallServices from '../../../../../services/supplier/supplier-recall'
import delegateService from '../../../../../services/supplier/delegate'
import workflowService from '../../../../../services/work-flow'
import _ from 'lodash'
import moment from 'moment-timezone'
import { OUTPUT_SHORTDATE_FORMAT, OUTPUT_DATE_FORMAT } from '../../../../../misc/commons'
import VueTagsInput from '@johmun/vue-tags-input'
import Vue from 'vue'
import tinymce from 'vue-tinymce-editor'
import {getCommunicationType} from '../../../../../misc/genericFunctions'
import ViewModal from '../../../../../components/part/shared/PreviewViewModal'
Vue.component('tinymce', tinymce)
export default {
    props: {
        communicationId: { type: String, default: null },
        communicationType: {type: String, default: ''},
        communicationSubType: {type: String, default: ''},
    },
    components: {
        VueTagsInput,
        ViewModal
    },
    data() {
        return {
            unreadMessages: this.$parent.$parent.$parent.unreadMessages,
            height: 0,
            OUTPUT_SHORTDATE_FORMAT: OUTPUT_SHORTDATE_FORMAT,
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,
            providers: [],
            providerSelection: [],
            selectedProvider: null,
            createduserid: null,
            userid: null,
            previousValue: null,
            conversationName: null,
            conversationMsg: null,
            conversationEditing: {
                id: null,
                name: null
            },
            conversations: [],
            admin: [],
            conversationsFiltered: [],
            viewModalKey: 0,

            messages: {},
            messagesF: [],

            messageContent: null,
            preloadConversationId: null,
            ProviderId: 0,

            isShowInternal: false,
            filesUpload: [],
            tag: '',
            tags: [],
            autocompleteItems: [],
            valueTag: [],
            bodyContent: null,
            value: [],
            options: [],
            emailError: '',
            domainError: '',
            emailDuplicateError: '',
            SupplierInfoMailList: [],
            items: null,
            item: {
                email: [],
                comment: null,
                OrganizationId: null,
                DelegatId: 0,
                files: new FormData()
            },
            hideattachement: 'false',
            showBtn: true,
            clicked: '',
            isShowToolbarOptions: 'true',
            selectedDelegateId: '',
            tinyOptions: {
                'height': 280,
                branding: false,
                statusbar: false,
                nonbreaking_force_tab: true,
                indentation: '30pt',
                content_style: 'p {font-size: 16px;color: #666666;font-family: Helvetica,Arial,sans-serif;}',
                setup: function(ed) {
                    ed.on('keydown', function(evt) {
                        if (evt.keyCode == 9){
                            ed.execCommand('mceInsertContent', false, '&emsp;&emsp;&emsp;')
                            evt.preventDefault()
                        }
                    })
                }

            },
            disabletoolbartinyOptions: {
                'height': 280,
                branding: false,
                statusbar: false,
                toolbar: false,
                menubar: false,
                indentation: '30pt',
                content_style: 'p {font-size: 16px;color: #666666;font-family: Helvetica,Arial,sans-serif;}',
                setup: function(ed) {
                    console.clear()
                }
            }
        }
    },
    created() {
        this.getSupplierInfoMailList()
        this.getRecipientsEmailList()
        this.userid = this.$store.state.user.id
        if (this.$route.query.conId && this.$route.query.conId > 0) {
            this.$store.state.lastedRouteNotAuth=''
            this.preloadConversationId = this.$route.query.conId
        }
        this.getSupplierEmail('SUPPLIER_INFORMATION_MAILS')
        this.$emit('onReLoadCommunicationData')
        this.getUserData()
    },
    watch: {
        selectedProvider(val) {
            if (val == null) {
                this.conversationsFiltered = this.conversations
                this.ProviderId=0
            } else {
                this.ProviderId=this.providers.find(x => x.providerCommunicationId == val).id
                this.conversationsFiltered = this.conversations.filter(x => x.provider_id == this.ProviderId)
            }
        }
    },
    computed: {
        isDisabled() {
            return !this.conversationsFiltered.length
        },
        filteredItems() {
            return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
            })
        },
    },
    mounted() {
        var a = this.$store.state.user.id
        this.getRecallConversations().then(() => {
            if (this.conversations.length > 0 && this.conversations[0] && this.conversations[0].id) {
                this.getConversationMessages(this.preloadConversationId ? this.preloadConversationId : this.conversations[0].id)
            }
        })
        this.$nextTick(() => {
            this.resizeEvent()
            window.addEventListener('resize', this.resizeEvent)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeEvent)
    },
    methods: {
        getUserData() {
            if (this.$store.state.user != '') {

                var userData = this.$store.state.user
                this.admin.title = userData.title
            }
            else {
                this.admin.title = ''
            }
        },
        async createRecallConversation() {
            var isValid = await this.$refs['observer'].validate()
            if (isValid) {
                recallServices.createRecallConversation(this.communicationId, this.selectedProvider,this.ProviderId, this.communicationType, { name: this.conversationName, msg: this.conversationMsg }).then(resp => {
                    if (!resp.error) {
                        this.getRecallConversations()
                        this.getConversationMessages(resp.data.d.data)
                        this.conversationName = null
                        this.conversationMsg = null
                        this.selectedProvider=null
                        this.$refs.createConDialog.hide()
                        this._showToast('Created Successfully!')
                    }
                })
            }
        },
        getProviderName(providerId, recallSiteId) {
            var pro = this.providers.find(x => x.id == providerId && x.recallSiteId == recallSiteId)
            return pro && pro.id ? pro.name : ''
        },
        initLoadMessage() {
            this.messagesF = []
            if (this.messages && this.messages.msg) {
                this.messages.msg.forEach(el => {
                    this.addMessage(el)
                })
            }
        },
        addMessage(el) {
            if (this.messagesF.length > 0) {
                var lastItem = this.messagesF[this.messagesF.length - 1]
                var lastMsgTime = null
                if (lastItem.msg.length > 0) {
                    lastMsgTime = lastItem.msg[lastItem.msg.length - 1].time
                }
                var day = null
                if (lastMsgTime) {
                    day = moment.unix(lastMsgTime)
                }
                if (lastMsgTime && day.isSame(moment.unix(el.t), 'day')) {
                    if (lastItem.user_id && lastItem.user_id == el.i) {
                        lastItem.msg.push({
                            type: el.s,
                            time: el.t,
                            content: this.tryDeserializeJson(el.c, el.s)
                        })
                    } else {
                        this.pushNew(el)
                    }
                } else {
                    this.messagesF.push({
                        time_separator: el.t
                    })
                    this.pushNew(el)
                }
            } else {
                this.pushNew(el)
            }
            this.$nextTick(() => {
                var chatWrap = this.$refs['chat-wrap']
                chatWrap.scrollTop = chatWrap.scrollHeight
            })
        },
        pushNew(el) {
            var user = null
            if (this.messages && this.messages.users) {
                user = this.messages.users.find(x => x.id == el.i)
            }
            this.messagesF.push({
                user_id: el.i,
                me: el.i == this.$store.state.user.id ? true : false,
                img: user && user.img ? user.img : null,
                full_name: user && user.fn ? user.fn : null,
                org_name: user && user.org ? user.org : null,
                title: user && user.title ? user.title : null,
                email: user && user.email ? user.email : null,
                phone: user && user.phone ? user.phone : null,
                org_type: user && user.org_type ? user.org_type : null,
                msg: [{
                    type: el.s,
                    time: el.t,
                    content: this.tryDeserializeJson(el.c, el.s)
                }]
            })
        },
        tryDeserializeJson(str, type) {
            if (type == 'Text' || type == 'Html') {
                return str
            }
            try {
                return JSON.parse(str)
            } catch (e) {
                return []
            }
        },
        resizeEvent() {
            if (window.innerWidth <= 767) {
                this.height = '400px'
            } else {
                var cal = window.innerHeight - this.$refs.chat.getBoundingClientRect().y - 9
                this.height = cal >= 400 ? cal + 'px' : '400px'
            }
        },
        getRecallConversations() {
            return new Promise((resolve, reject) => {
                recallServices.getRecallConversations(this.communicationId).then(resp => {
                    if (!resp.error) {
                        this.conversations = resp.data.d.conversations
                        this.conversationsFiltered = resp.data.d.conversations
                        this.providers = resp.data.d.providers
                        this.providerSelection = this.providers.filter(x => x.isSettingEnabled !== 1)
                        for (let i = 0; i < this.providers.length; i++) {
                            this.providers[i].name=this.providers[i].name +(this.providers[i].sitename!=null? '-'+ this.providers[i].sitename :'' )
                        }
                        this.unreadMessages = 0
                        this.conversations.forEach(x => this.unreadMessages += x.unread)
                        this.$parent.$parent.$parent.unreadMessages = this.unreadMessages
                        resolve()
                    }
                }).finally(() => {
                    reject()
                })
            })
        },
        updateRecallConversation() {
            recallServices.updateRecallConversation(this.communicationId, this.conversationEditing).then(resp => {
                if (!resp.error) {
                    this.getRecallConversations()
                    this._showToast('Updated Successfully!')
                    this.cancellUpdate()
                }
            })
        },
        cancellUpdate() {
            this.conversationEditing.name = this.previousValue
            this.conversationEditing = {
                id: null,
                name: null
            }
        },
        editConversation(item) {
            this.previousValue = item.name
            if (item.createdUserId == this.$store.state.user.id && item.supplierid != 0) {
                this.conversationEditing = item
            }
            else {
                this._showToast('Cannot edit conversation name created by other users', { variant: 'danger' })
            }
        },
        removeUnreadMessages(num){
            if(num == null || num == undefined || num == 0)
                return
            this.unreadMessages = this.unreadMessages - num
            this.$parent.$parent.$parent.unreadMessages = this.unreadMessages
        },
        getConversationMessages(conversationId) {

            recallServices.getRecallConversation(this.communicationId, conversationId).then(resp => {
                if (!resp.error) {
                    var unread = 0
                    this.conversations.forEach(el => {
                        if (el.id == conversationId) {
                            this.removeUnreadMessages(el.unread)
                            el.unread = 0
                        }
                    })
                    this.conversationsFiltered.forEach(el => { if (el.id == conversationId) { el.unread = 0 } })
                    this.messages = resp.data.d
                    this.initLoadMessage()
                }
            })
        },
        sendMessage(type, files = null) {
            let formData = new FormData()
            let isValid = true
            formData.append('messageContent', this.messageContent ? this.messageContent.trim() : '')
            formData.append('messageType', type)
            if (files && _.isArray(files)) {
                files.forEach(file => {
                    formData.append('files', file)
                })
            }
            if ((type == 'Image' || type == 'File') && files.length == 0) {
                isValid = false
            }
            if (isValid) {
                recallServices.createRecallConversationMessage(this.communicationId, this.messages.id, formData).then(resp => {

                    if (resp.data.ok == true) {
                        this.addMessage(resp.data.d.data)
                        this.messageContent = null
                    } else {
                        this._showToast('Send Failed!', { variant: 'danger' })
                    }
                })
            }
        },
        uploadPhoto(files) {
            this.sendMessage('Image', files)
        },
        uploadFile(files) {
            this.sendMessage('File', files)
        },
        ViewClick(val) {
            let itemVal = {
                extension: val.extension,
                filename: val.name,
                signed_url: val.signed_url,
                signed_url_review: val.signed_url_review,
                fileSize: val.size,
                mimetype: val.type
            }
            this.viewModalKey += 1
            this.$nextTick(() => {
                this.$refs.viewModal.forceRerenderInsert(itemVal)
            })
        },
        downloadFile(fileId) {
            recallServices.getRecallConversationMessageFile(this.communicationId, this.messages.id, fileId).then(resp => {
                if (!resp.error && resp.data && resp.data.d && resp.data.d.signed_url) {
                    this.ViewClick(resp.data.d)
                    //this.openExternalLink(resp.data.d.signed_url)
                }
            })
        },
        uploadInternalNotiPhoto(files) {
            this.sendMessage('Image', files)
        },
        uploadInternalNotiAttachmentFile(files) {
            this.filesUpload.push({
                name: files.currentTarget.files[0].name,
                size: files.currentTarget.files[0].size,
                type: files.currentTarget.files[0].type,
                file: files.currentTarget.files[0],
            })
            files.currentTarget.value= ''
        },
        uploadInternalNotiFile(files) {
            this.sendMessage('File', files)
        },
        removeDocument(index) {
            this.communcationPanelHeight = this.communcationPanelHeight - 60
            this.filesUpload.splice(index, 1)
        },
        // For Internal Notifications
        getSupplierEmail(type) {
            this.emailError=''
            this.options=[]
            this.valueTag=[]
            this.value = []
            this.domainError = ''
            this.selectedDelegateId=''
            this.isShowToolbarOptions='true'
            this.filesUpload = []
            type = 'SUPPLIER_INFORMATION_MAILS'
            delegateService.getSupplierInformationEmail(type).then(resp => {
                if (!resp.error) {
                    this.emailItem = resp.data.d
                    this.emailItem.content=resp.data.d.content.replaceAll('${CommunicationType}',getCommunicationType(this.communicationType,this.communicationSubType))
                    this.showBtn = true
                    if(this.items == null)
                    {
                        recallServices.getCommunicationSummaryById(this.communicationId).then(resp1 => {
                            if (!resp1.error) {
                                this.items = resp1.data.d
                                var bodyReplace = /<body.*?>([\s\S]*)<\/body>/.exec(resp.data.d.content.replace('${SupplierUserName}', this.$store.state.user.full_name).replace('${SupplierRole}', this.$store.state.user.role).replace('${SupplierName}', this.$store.state.user.org_name).replace('${SupplierName}', this.items == null ? '' : this.items.supplier_name).replace('${EventName}', this.items == null ? '' : this.items.name)
                                    .replace('${CommunicationContent}', '').replaceAll('${displayOld}', 'block !important')
                                )[1]
                                if (this.admin.title == undefined || this.admin.title == null) {
                                    bodyReplace = bodyReplace.replace('<p style="margin-bottom: 0px; margin-top: 0px;display: block !important;">${SupplierUserTitle}</p>', '')
                                    bodyReplace = bodyReplace.replace(' <p style="display: block !important; margin-bottom: 0px; margin-top: 0px;">${SupplierUserTitle}</p>', '')
                                    bodyReplace = bodyReplace.replace('${SupplierUserTitle}', ' ')
                                }
                                else
                                {
                                    bodyReplace = bodyReplace.replace('${SupplierUserTitle}', this.admin.title)
                                }
                                bodyReplace = bodyReplace.replaceAll('<p style="display: block !important;"><br></p>','')
                                this.bodyContent = bodyReplace.replace('${EventName}', this.items == null ? '' : this.items.name)
                                this.bodyContent = this.bodyContent.replace('<span id="repTxt" style="display: block','<span id="repTxt" style="display: inline-block')
                                this.hideattachement = 'false'
                            }
                        })
                    }
                    else
                    {
                        var bodyReplace = /<body.*?>([\s\S]*)<\/body>/.exec(resp.data.d.content.replace('${SupplierUserName}', this.$store.state.user.full_name).replace('${SupplierRole}', this.$store.state.user.role).replace('${SupplierName}', this.$store.state.user.org_name).replace('${SupplierName}', this.items == null ? '' : this.items.supplier_name).replace('${EventName}', this.items == null ? '' : this.items.name)
                            .replace('${CommunicationContent}', '').replaceAll('${displayOld}', 'block !important')
                        )[1]
                        if (this.admin.title == undefined || this.admin.title == null) {
                            bodyReplace = bodyReplace.replace('<p style="margin-bottom: 0px; margin-top: 0px;display: block !important;">${SupplierUserTitle}</p>', '')
                            bodyReplace = bodyReplace.replace(' <p style="display: block !important; margin-bottom: 0px; margin-top: 0px;">${SupplierUserTitle}</p>', '')
                            bodyReplace = bodyReplace.replace('${SupplierUserTitle}', ' ')
                        }
                        else
                        {
                            bodyReplace = bodyReplace.replace('${SupplierUserTitle}', this.admin.title)
                        }
                        bodyReplace = bodyReplace.replaceAll('<p style="display: block !important;"><br></p>','')
                        this.bodyContent = bodyReplace.replace('${EventName}', this.items == null ? '' : this.items.name)
                        this.bodyContent = this.bodyContent.replace('<span id="repTxt" style="display: block','<span id="repTxt" style="display: inline-block')
                        this.hideattachement = 'false'
                    }
                }
            })
        },

        addCheck(val) {
            if (val.text) {
                this._showToast('The email has been already used.')
            }
            else {
                if (val.length > 0) {
                    this.value = []
                    val.forEach(x => {
                        if (x.is_dl == true) {
                            const tag = {
                                email: x.text,
                                is_dl: x.is_dl,
                            }
                            this.value.push(tag)
                        } else {
                            const tag = {
                                email: x.text,
                                is_dl: false,
                            }
                            this.checkEmailValidation(tag)
                            if (!this.emailError) {
                                this.options.push(tag)
                                this.value.push(tag)
                                this.checkDomainValidation(tag)
                            }
                        }
                    })
                    if (this.value.length > 1) {
                        var valueOriginal = this.value.length
                        var valcurrent = val.length - 1
                        const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
                        var b = re.test(String(val[valcurrent].text.trim()).toLowerCase())
                        if (b) {
                            var i = 0
                            for (i = 0; i < valueOriginal - 1; i++) {

                                if (this.value[i].email.trim() === val[valcurrent].text.trim()) {
                                    this.emailError = 'The email has been already used.'
                                }
                            }

                        }
                    }

                }
                else {
                    this.value = []
                    this.emailError = ''
                    this.domainError = ''
                }
            }
        },
        checkDomainValidation(tag) {
            this.domainError = ''
            delegateService.getSupplierDamain(tag.email, this.$store.state.orgId).then(resp => {
                if (resp.error) {
                    this.domainError = 'domainError'
                    this.options.pop(tag)
                    this.value.pop(tag)
                    return false
                }
                else {
                    return true
                }
            })
        },
        checkEmailValidation(tag) {
            if (!tag) {
                this.emailError = 'Email is required'
            }
            else { this.emailError = '' }
            var currentTag = tag
            if (currentTag) {
                const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
                var b = re.test(String(tag.email).toLowerCase())
                if (!b) {
                    this._showToast('Please enter valid email address', { variant: 'warning' })
                }
            }
        },
        getAttachementByEmail(emailname,delegateId) {
            this.selectedDelegateId=delegateId
            this.isShowToolbarOptions='false'
            this.hideattachement = 'true'
            delegateService.getSupplierAttachementByEmail(this.communicationId, this.$store.state.orgId, emailname,delegateId).then(respattach => {
                if (!respattach.error) {
                    respattach.data.d[0].data_content=respattach.data.d[0].data_content?.replaceAll('${CommunicationType}',getCommunicationType(this.communicationType,this.communicationSubType))
                    this.bodyContent = ''
                    this.filesUpload = ''
                    this.showBtn = false
                    if (respattach.data.d[0].name != null) { this.filesUpload = respattach.data.d }
                    else { this.filesUpload = 0 }
                    if (this.items == null) {
                        recallServices.getRecallSummaryById(this.communicationId).then(resp1 => {
                            if (!resp1.error) {
                                this.items = resp1.data.d
                                var bodyReplace = /<p.*?>([\s\S]*)<\/p>/.exec(respattach.data.d[0].data_content.replace('${SupplierUserName}', this.$store.state.user.full_name).replace('${SupplierRole}', this.$store.state.user.role).replace('${SupplierName}', this.$store.state.user.org_name).replace('${SupplierName}', this.items == null ? '' : this.items.supplier_name).replace('${EventName}', this.items == null ? '' :  this.items.communicationName)
                                    .replace('${CommunicationContent}', '').replaceAll('${displayOld}', 'block !important')
                                )[1]
                                bodyReplace = bodyReplace.replaceAll('<p style="display: block !important;"><br></p>','')
                                this.bodyContent = bodyReplace.replace('${EventName}', this.items == null ? '' : this.items.communicationName)
                            }
                        })

                    }
                    else {
                        this.bodyContent = ''
                        var obj = JSON.parse(respattach.data.d[0].data_content)
                        var array = obj.CommunicationContent.split('<span>')
                        if (obj.CommunicationContent.includes('<p style="display: block !important;">Hello,') || obj.CommunicationContent.includes('<p>Hello,')) {
                            var bodyReplace = /<p.*?>([\s\S]*)<\/p>/.exec(respattach.data.d[0].data_content.replace('${SupplierUserName}', this.$store.state.user.full_name).replace('${SupplierRole}', this.$store.state.user.role).replace('${SupplierName}', this.$store.state.user.org_name).replace('${SupplierName}', this.items == null ? '' : this.items.supplier_name).replace('${EventName}', this.items == null ? '' : this.items.name).replace('${CommunicationContent}', '').replaceAll('${displayOld}', ''))[1]
                            bodyReplace = bodyReplace.replaceAll('<p style="display: block !important;"><br></p>','')
                            this.bodyContent = bodyReplace.replace('${EventName}', this.items == null ? '' : this.items.name).replaceAll('<p style="display: block;margin-bottom: -10px;">', '<p>')
                        }
                        else {
                            array.forEach(el => {
                                if (el != '')
                                    this.bodyContent += '<p>' + el.replace('</span>', '') + '</p>'
                            })
                            this.bodyContent =  this.bodyContent.replaceAll('<p><br></p>','')
                            this.bodyContent =  this.bodyContent.replaceAll('<br>'+this.$store.state.user.full_name,this.$store.state.user.full_name)
                            this.bodyContent =  this.bodyContent.replaceAll('<p>','<p style="margin-bottom: 0px;margin-top:0px">')
                        }
                    }


                }

            })
        },
        createDelegate() {
            if (this.domainError == 'domainError') {
                return this._showToast('The email domain is not valid.', { title: 'Error', variant: 'danger' })
            }
            var htmls = this.bodyContent
            if (this.value.length > 1) {
                for (var i = 0; i < this.value.length; i++) {
                    for (var k = i + 1; k < this.value.length; k++) {
                        if (this.value[i].email.trim() === this.value[k].email.trim()) {
                            return this.emailError = 'The email has been already used.'
                        }

                    }

                }
            }
            if (this.value.length == 0) {
                return  this.emailError = 'Email is required'
            }
            else { this.emailError = '' }
            let formData = new FormData()
            for (let k = 0; k < this.value.length; k++) {
                if (this.value[k].is_dl)
                    formData.append('distributionlist', this.value[k].email)
                else
                    formData.append('emaillist', this.value[k].email)
            }
            formData.append('communicationcontent', htmls)
            this.filesUpload.forEach((x, i) => {
                formData.append('document', x.file)
            })
            formData.append('communicationName',  (this.items == null ? '' : this.items.name))
            formData.append('replacedelegateurl', '<a href="${Url}">' + (this.items == null ? '' : this.items.name) + '</a>')

            workflowService.sendInfoMail(this.communicationId,this.communicationType, formData).then(resp => {
                if (!resp.error) {
                    this._showToast('Email sent successfully!')
                    this.getSupplierInfoMailList()
                    this.filesUpload = []
                    this.valueTag = []
                    this.getRecipientsEmailList()
                    if (this.$route.query.conId && this.$route.query.conId > 0) {
                        this.preloadConversationId = this.$route.query.conId
                    }
                    this.getSupplierEmail('SUPPLIER_INFORMATION_MAILS')
                    this.isShowInternal = true
                }
            })
            this.value = ''
        },

        getRecipientsEmailList()
        {
            delegateService.getRecallAction_SupplierEmailList(this.$store.state.orgId, this.communicationId).then(resp => {
                if (!resp.error) {
                    this.autocompleteItems=[]
                    resp.data.d.forEach((val) => {
                        this.autocompleteItems.push({ text: val.email, is_dl: val.is_dl })
                    })
                }
            })
        },
        getSupplierInfoMailList()
        {
            return new Promise((resolve, reject) => {
                delegateService.getSupplierInfoMailList(this.communicationId, this.$store.state.orgId).then(resp => {
                    if (!resp.error) {
                        this.SupplierInfoMailList = resp.data.d
                        resolve()
                    }
                }).finally(() => {
                    reject()
                })
            })
        },
    }
}