import workflowServices from '../../../../../../services/work-flow'
import recallServices from '../../../../../../services/supplier/supplier-recall'
import store from '../../../../../../store'
import moment from 'moment'
export default {
    components: {
    },
    data() {
        return {
            title: '',
            formType: '',
            item: [],
            communicationId: null,
            orgType: store.state.userType,
            providerCommunicationId: null,
            communicationType: '',
            selectedSendNotification: 'REMINDER_EMAIL'
        }
    },

    methods: {
        forceRerenderView(item,communicationId,type,providerCommunicationId,communicationType) {
            this.communicationId=communicationId
            this.providerCommunicationId=providerCommunicationId
            this.item = item
            this.communicationType = communicationType
            this.title = 'Provider Reminder'
            this.formType = type
            this.$refs.modal.show()
        },
        cancel() {
            this.$refs.modal.hide()
        },
        save()
        {
            recallServices.sendNotification(this.communicationId, this.selectedSendNotification, this.communicationType, null, null).then(resp => {
                if (!resp.error) {
                    this._showToast('Email sent successfully!')
                    this.$refs.modal.hide()
                }
            })
        }
    }

}