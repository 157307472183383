import { http, param } from '../../utils/http'
import conf from '../../config'
import fileDownload from 'js-file-download'

export const API_ROOT = `${conf.API_URL}`

const DelegateService = {
    //Provider info mail

    async getSupplierDamain(id, communicationId) {
        return http.get(`${API_ROOT}/supplier/communications/valid-domain/emailid/${id}/${communicationId}`).then(resp => {
            return resp
        })
    },
    getSupplierInformationEmail(type) {
        return http.get(`${API_ROOT}/supplier/communications/supplierInfoMail/${type}`).then(resp => {
            return resp
        })
    },
    getRecallAction_SupplierEmailList (organizationId,communicationId) {
        return http.get(`${API_ROOT}/supplier/communications/${organizationId}/${communicationId}/supplieremaillist`).then(resp => {
            return resp
        })
    },
    getSupplierInfoMailList (communicationId,providerOrgId) {
        return http.get(`${API_ROOT}/supplier/communications/supplierMailList/${communicationId}/${providerOrgId}`).then(resp => {
            return resp
        })
    },
    getSupplierAttachementByEmail (communicationId, OrganizationId,emailname,delegateId) {
        return http.get(`${API_ROOT}/supplier/communications/supplierMail/${communicationId}/infomail/${OrganizationId}/${emailname}/${delegateId}`).then(resp => {
            return resp
        })
    },

}

export default DelegateService