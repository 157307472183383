import _ from 'lodash'
import recallServices from '../../../../../../services/supplier/supplier-recall'
import VueTagsInput from '@johmun/vue-tags-input'
import ViewModal from '../../../../../../components/part/shared/PreviewViewModal'
import moment from 'moment-timezone'
import ActionType from './../../../../../../components/part/provider/PaperResponseActionType'
export default {
    components: {
        VueTagsInput,
        ActionType,
        ViewModal
    },
    props: {
        totalItem: { type: [], default: null }
    },
    data() {
        return {
            communicationType: { type: String, default: '' },
            communicationId: this.$parent.communicationId,
            actions: null,
            isExpanded: false,
            cnt: 1,
            editMode: true,
            item: {
                idn: null,
                providerName: null,
                location: null,
                responder: null,
                supplierActions: null,
                notes: null,
                responseDate: new Date(),
                responseType: 'Paper',
                paperresponseid: null,
            },
            valueTag: [],
            tags: [],
            tag: '',
            locationObj: '',
            selectedLocations: [],
            value: [],
            locName: '',
            valueTagS: [],
            tagsS: [],
            tagS: '',
            valueS: [],
            isDisabled: false,
            isDisabledLocation: false,
            notification_date: null,
            title: 'New Paper Response',
            selectedResponseType: 'Paper',
            filesUpload: [],
            uploadedFiles: [],
            NotificationfilesUpload: [],
            thumbnailIndex: -1,
            viewModalKey: 0,
            sup: 'sup',
            IsVal: false,
            disabledDates: {
                from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            },
            autocompleteItemslst: [],
            autocompleteItemsLoc: [],
            autocompleteItemsLocByIDN: [],
            autocompleteItemsEditLocByIDN: [],
            RESPONSE_TYPE: [
                { value: 'Paper', text: 'Paper' },
                { value: 'Email', text: 'Email' },
                { value: 'Phone', text: 'Phone' },
            ],
            locationList: [],
            locationListFiltered: [],
            formType: ''
        }
    },
    computed: {
        isRequired() {
            return true
        },
        filteredItems() {
            this.autocompleteItemsLoc = []
            for (let i = 0; i < this.autocompleteItemsLocByIDN.length; i++) {
                if (this.autocompleteItemsLocByIDN[i].idn == this.tagS) {
                    this.autocompleteItemsLoc.push(this.autocompleteItemsLocByIDN[i].location_name)
                }
            }
            return this.autocompleteItemsLoc.filter(i => {
                if (i != null)
                    return i.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
            })
        },
        filteredSupItems() {
            return this.autocompleteItemslst.filter(i => {
                if (i != null)
                    return i.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
            })
        },
    },
    mounted() {
        this.fetchProviderNameList()
        this.totalItem.forEach((action) => {
            action.location_name.split(',').forEach(x => {
                this.selectedLocations.push(x)  //this.valueTag.push(x)
            })
        })
    },
    methods: {
        getRecallActions(formData) {
            recallServices.getRecallActionsForPaperResponse(formData).then(resp => {
                if (!resp.error) {
                    var actions = []
                    resp.data.d.forEach((action) => {
                        if (action.isCustomActionType == false) {
                            actions.push(action)
                        }
                        else {
                            let clidlActions = resp.data.d.filter(x => x.baseActionId == action.id)
                            if (clidlActions.length > 0) {
                                actions.push(action)
                            }
                        }

                    })
                    let baseactions = actions.filter(x => x.baseActionId == 0)
                    baseactions.forEach((action) => {
                        let clidlActions = actions.filter(x => x.baseActionId == action.id)
                        clidlActions.forEach((child) => {
                            child.description = child.description.replace(action.description + ' - ', '')
                        })

                    })
                    this.actions.forEach(function (action) {
                        if (action.isExpanded) {
                            for (let i = 0; i < actions.length; i++) {
                                if (actions[i].id == action.id) {
                                    actions[i].isExpanded = action.isExpanded
                                }
                                if (actions[i].baseActionId == action.id) {
                                    actions[i].show = action.isExpanded
                                }
                            }
                        }
                    })
                    for (let i = 0; i < actions.length; i++) {
                        let List = []
                        let len = actions[i].actionValues.length
                        let act = actions[i]
                        actions[i].childactioncount = actions.filter(s => s.baseActionId != 0 && s.baseActionId == actions[i].id).length
                        if (actions[i].action_type == 'MultipleChoice') {
                            for (let j = 0; j < len; j++) {
                                List.push({
                                    value: this.cnt,
                                    text: act.actionValues[j].value,
                                })
                                if (!act.isAllowOnlyOne) {
                                    act.response_data.valueMultiChoice.forEach((element) => {
                                        if (element.valueMultiChoice == act.actionValues[j].value) {
                                            act.multiSelectedValues.push(this.cnt)
                                        }
                                    })
                                } else {
                                    if (act.response_data.valueMultiChoice == undefined) {
                                        if (act.response_data.valueSingleChoice != undefined) {
                                            if (act.response_data.valueSingleChoice == act.actionValues[j].value) {
                                                act.singleSelectedValues = this.cnt
                                            }
                                        }
                                    }
                                    else {
                                        if (act.response_data.valueMultiChoice.length > 0) {
                                            if (act.response_data.valueMultiChoice[0].valueMultiChoice == act.actionValues[j].value) {
                                                act.singleSelectedValues = this.cnt
                                            }
                                        }
                                    }
                                }
                                this.cnt++
                            }
                        }
                        actions[i].actionValues = []

                        actions[i].actionValues = List
                    }
                    this.actions = []
                    this.actions = actions
                }
            })
        },
        expandMoreLess(action) {
            if (action.isCustomActionType) {
                action.isExpanded = action.isCustomActionType ? !action.isExpanded : action.isExpanded
            }
        },
        GetNotifiedDate() {
            recallServices.getRecallById(this.communicationId).then(resp => {
                if (!resp.error) {

                    this.disabledDates.to = new Date(new Date(resp.data.d.communicationDate.split(' ')[0]).getFullYear(), new Date(resp.data.d.communicationDate.split(' ')[0]).getMonth(), (new Date(resp.data.d.communicationDate.split(' ')[0]).getDate()))
                }
            })
        },
        forceRerenderInsert(list) {
            this.formType = 'INSERT'
            this.communicationType = list.communicationType
            this.fetchLocationList()
            this.GetNotifiedDate()
            this.paperresponseid = 0
            this.isDisabled = false
            this.isDisabledLocation = false
            this.$refs.modal.show()
        },
        getSupplierActions(formData) {
            recallServices.getPaperResponseActions(formData).then(resp => {
                if (!resp.error) {
                    var actions = []
                    resp.data.d.forEach((action) => {
                        if (action.isCustomActionType == false) {
                            actions.push(action)
                        }
                        else {
                            let clidlActions = resp.data.d.filter(x => x.baseActionId == action.id)
                            if (clidlActions.length > 0) {
                                actions.push(action)
                            }
                        }

                    })
                    this.actions = actions
                    let baseactions = this.actions.filter(x => x.baseActionId == 0)
                    baseactions.forEach((action) => {
                        let clidlActions = this.actions.filter(x => x.baseActionId == action.id)
                        clidlActions.forEach((child) => {
                            child.description = child.description.replace(action.description + ' - ', '')
                        })

                    })
                    for (let i = 0; i < this.actions.length; i++) {
                        let List = []
                        let len = this.actions[i].actionValues.length
                        let act = this.actions[i]
                        this.actions[i].childactioncount = this.actions.filter(s => s.baseActionId != 0 && s.baseActionId == this.actions[i].id).length
                        if (this.actions[i].action_type == 'MultipleChoice') {
                            for (let j = 0; j < len; j++) {
                                List.push({
                                    value: this.cnt,
                                    text: act.actionValues[j].value,
                                })
                                if (!act.isAllowOnlyOne) {
                                    act.response_data.valueMultiChoice.forEach((element) => {
                                        if (element.valueMultiChoice == act.actionValues[j].value) {
                                            if (act.multiSelectedValues == null) {
                                                act.multiSelectedValues = []
                                            }
                                            act.multiSelectedValues.push(this.cnt)
                                        }
                                    })
                                } else {
                                    if (act.response_data.valueMultiChoice == undefined) {
                                        if (act.response_data.valueSingleChoice != undefined) {
                                            if (act.response_data.valueSingleChoice == act.actionValues[j].value) {
                                                act.singleSelectedValues = this.cnt
                                            }
                                        }
                                    }
                                    else {
                                        if (act.response_data.valueMultiChoice.length > 0) {
                                            if (act.response_data.valueMultiChoice[0].valueMultiChoice == act.actionValues[j].value) {
                                                act.singleSelectedValues = this.cnt
                                            }
                                        }
                                    }
                                }
                                this.cnt++
                            }
                            this.actions[i].actionValues = []
                            this.actions[i].actionValues = List
                        }
                    }
                }
            })
        },
        forceRerenderEdit(list) {
            this.responseDateEdit()
            this.fetchDocumentsById(parseInt(list.paper_response_id))
            this.responseDateEdit()
            this.GetNotifiedDate()
            this.fetchLocationListForEdit()
            this.title = 'Update Paper Response'
            this.formType = 'EDIT'
            this.communicationType = list.communicationType
            this.item.notes = list.notes
            this.item.paperresponseid = parseInt(list.paper_response_id)
            this.item.responder = list.responder
            this.item.responseType = list.response_type
            this.item.providerName = list.idn
            this.item.responseDate = list.response_date
            this.locName = list.location_name
            this.selectedResponseType = list.response_type
            list.location_name.split(',').forEach(x => {
                this.valueTag.push(x)
            })
            list.location_name.split(',').forEach(x => {
                this.value.push(x)
            })
            this.tagS = list.idn
            this.getSupplierActions(parseInt(list.paper_response_id))
            this.valueS.push(list.idn)
            this.isDisabled = false
            this.isDisabledLocation = true
            this.$refs.modal.show()
        },
        forceRerenderView(list) {
            this.fetchDocumentsById(parseInt(list.paper_response_id))
            this.getSupplierActions(parseInt(list.paper_response_id))
            this.fetchLocationListForEdit()
            this.title = 'View Paper Response'
            this.editMode = false
            this.formType = 'VIEW'
            this.isDisabled = true
            this.isDisabledLocation = true
            this.tagS = list.idn
            list.location_name.split(',').forEach(x => {
                this.valueTag.push(x)
            })
            this.item.notes = list.notes
            this.selectedResponseType = list.response_type
            this.item.responder = list.responder
            this.item.responseType = list.response_type
            this.item.providerName = list.idn
            this.item.responseDate = list.response_date
            this.locName = list.location_name
            this.$refs.modal.show()
        },
        fetchDocumentsById(id) {
            recallServices.getDocForPaperResponse(id).then((res) => {
                if (!res.error) {
                    var uploadedFiles = res.data.d
                    this.filesUploadOriginal = []
                    for (let i = 0; i < uploadedFiles.length; i++) {
                        this.filesUpload.push({
                            extension: uploadedFiles[i].extension,
                            name: uploadedFiles[i].filename,
                            type: uploadedFiles[i].mimetype,
                            size: uploadedFiles[i].fileSize,
                            signed_url: uploadedFiles[i].signed_url,
                            file_id: uploadedFiles[i].file_id,
                            paper_response_id: uploadedFiles[i].paper_response_id,
                            path: uploadedFiles[i].path,
                        })
                        this.filesUploadOriginal.push({
                            extension: uploadedFiles[i].extension,
                            name: uploadedFiles[i].filename,
                            type: uploadedFiles[i].mimetype,
                            size: uploadedFiles[i].fileSize,
                            signed_url: uploadedFiles[i].signed_url,
                            file_id: uploadedFiles[i].file_id,
                            paper_response_id: uploadedFiles[i].paper_response_id,
                            path: uploadedFiles[i].path,
                        })
                    }
                }
            })
        },
        async download(dataurl) {
            this.ViewClick(dataurl)
        },
        ViewClick(val) {
            let itemVal = {
                extension: val.extension,
                filename: val.name,
                signed_url: val.signed_url,
                signed_url_review: val.signed_url_review,
                fileSize: val.size,
                mimetype: val.type
            }
            this.viewModalKey += 1
            this.$nextTick(() => {
                this.$refs.viewModal.forceRerenderInsert(itemVal)
            })
        },
        addCheck(val) {
            if (val.length > 0) {
                let obj = val.slice(-1)[0].text
                if (this.autocompleteItemsLoc.indexOf(obj) != -1) {
                    this.value = []
                    val.forEach(x => {
                        this.value.push(x.text)
                    })
                }
                else {
                    val.pop()
                    val.slice(0, val.length - 1)
                    this.value = []
                    val.forEach(x => {
                        this.value.push(x.text)
                    })
                }
            }
            let formData = new FormData()
            formData.append('communicationId', this.$parent.communicationId)
            if (this.locationObj.length > 0) {
                for (let i = 0; i < this.locationObj.length; i++) {
                    formData.append('locationsid', this.locationObj[i])
                }
                this.getRecallActions(formData)
            }
            else {
                this.actions = null
            }
        },
        addSupCheck(val) {
            if (val.length > 0) {
                if (val.length > 1) {
                    val.pop()
                    val.slice(0, val.length - 1)
                    this.valueS = []
                    val.forEach(x => {
                        this.valueS.push(x.text)
                    })
                }
                else {
                    let obj = val.slice(-1)[0].text
                    if (this.autocompleteItemslst.indexOf(obj) != -1) {
                        this.valueS = []
                        val.forEach(x => {
                            this.valueS.push(x.text)
                        })
                    }
                    else {
                        val.pop()
                        val.slice(0, val.length - 1)
                        this.valueS = []
                        val.forEach(x => {
                            this.valueS.push(x.text)
                        })
                    }
                }
            }
            if (val.length == 0) {
                this.autocompleteItemsLoc = []
                this.valueS = []
                this.value = []
                this.valueTag = []
                this.actions = []
            }
            this.setLocations()
        },
        fetchProviderNameList() {
            recallServices.getProviderNamesForPaperResponse(this.$parent.communicationId).then((res) => {
                for (let i = 0; i < res.data.d.length; i++) {
                    this.autocompleteItemslst.push(res.data.d[i].idn)
                }
            })
        },
        fetchLocationList() {
            recallServices.getLocationForPaperResponse(this.$parent.communicationId).then((res) => {
                for (let i = 0; i < res.data.d.length; i++) {
                    this.autocompleteItemsLocByIDN.push(res.data.d[i])
                    this.locationList.push({
                        isSelected: false,
                        status: 'ACTIVE',
                        text: res.data.d[i].location_name,
                        value: res.data.d[i].id,
                        idn: res.data.d[i].idn
                    })
                }
            })
        },
        fetchLocationListForEdit() {
            recallServices.getLocationForEditPaperResponse(this.$parent.communicationId).then((res) => {
                this.locationList = []
                for (let i = 0; i < res.data.d.length; i++) {
                    this.autocompleteItemsEditLocByIDN.push(res.data.d[i])
                    this.locationList.push({
                        isSelected: false,
                        status: 'ACTIVE',
                        text: res.data.d[i].location_name,
                        value: res.data.d[i].id,
                        idn: res.data.d[i].idn
                    })
                }
                this.setLocations()
            })
        },
        async removeDocument(index) {
            this.filesUpload.splice(index, 1)
            this.thumbnailIndex = this.filesUpload.findIndex(x => x.is_thumbnail === true)
        },
        async handleFilesUpload() {
            let uploadedFiles = this.$refs.files1.files
            for (let i = 0; i < uploadedFiles.length; i++) {
                if (uploadedFiles[i].size == 0) {
                    this._showToast('Documented failed to upload. Please try again.', { variant: 'danger' })
                }
                else {
                    this.filesUpload.push({
                        file: uploadedFiles[i],
                        name: uploadedFiles[i].name,
                        type: uploadedFiles[i].type,
                        size: uploadedFiles[i].size,
                    })
                }
            }
            this.$refs.files1.value = ''
        },
        showModal() {
            this.$refs.modal.show()
        },
        async createRecall(e) {
            var isValid = await this.$refs['observer'].validate()
            if (isValid) {
                var act = this.actions
                for (let i = 0; i < this.actions.length; i++) {
                    if (act[i].action_type == 'MultipleChoice') {
                        if (act[i].multiSelectedValues == null && (act[i].singleSelectedValues == null || act[i].singleSelectedValues == 0)) {
                            this.actions[i].singleSelectedValues = 0
                        }
                        else if (act[i].multiSelectedValues?.length == 0 && (act[i].singleSelectedValues == null || act[i].singleSelectedValues == 0)) {
                            this.actions[i].singleSelectedValues = 0
                        }
                    }
                }
                let formData = new FormData()
                var actionDoc = this.$refs.actionModal
                var cnt = 0
                if (this.formType == 'INSERT') {
                    for (let i = 0; i < actionDoc.length; i++) {
                        if (this.$refs.actionModal[i].filesUpload.length > 0) {
                            this.$refs.actionModal[i].filesUpload.forEach((x, j) => {
                                formData.append('actiondocument', x.file)
                                formData.append('actionsfiles[' + cnt + '].filename', x.name)
                                formData.append('actionsfiles[' + cnt + '].actionId', x.actionId)
                                cnt++
                            })
                        }
                    }
                }
                else {
                    for (let i = 0; i < actionDoc.length; i++) {
                        if (this.$refs.actionModal[i].type == 'Image' || this.$refs.actionModal[i].type == 'Document') {
                            if (this.$refs.actionModal[i].type == 'Image') {
                                if (this.$refs.actionModal[i].value.valueImage != undefined) {
                                    if (this.$refs.actionModal[i].value.valueImage.length > 0) {
                                        this.$refs.actionModal[i].value.valueImage.forEach((x, j) => {
                                            if (x.id == 0) {
                                                formData.append('actiondocument', x.file)
                                                formData.append('actionsfiles[' + cnt + '].filename', x.name)
                                                formData.append('actionsfiles[' + cnt + '].actionId', x.actionId)
                                                cnt++
                                            }
                                            else {
                                                formData.append('fileids', x.id)
                                            }
                                        })
                                    }
                                }
                            }
                            else if (this.$refs.actionModal[i].type == 'Document') {
                                if (this.$refs.actionModal[i].value.valueDocument != undefined) {
                                    if (this.$refs.actionModal[i].value.valueDocument.length > 0) {
                                        this.$refs.actionModal[i].value.valueDocument.forEach((x, j) => {
                                            if (x.id == 0) {
                                                formData.append('actiondocument', x.file)
                                                formData.append('actionsfiles[' + cnt + '].filename', x.name)
                                                formData.append('actionsfiles[' + cnt + '].actionId', x.actionId)
                                                cnt++
                                            }
                                            else {
                                                formData.append('fileids', x.id)
                                            }
                                        })
                                    }
                                }
                            }
                        }
                    }
                }
                formData.append('paperresponseid', this.item.paperresponseid == null ? 0 : this.item.paperresponseid)
                formData.append('communicationId', this.$parent.communicationId)
                formData.append('responder', this.item.responder)
                formData.append('responsetype', this.selectedResponseType)
                formData.append('responsedate', this.item.responseDate ? moment(this.item.responseDate).format('YYYY/MM/DD') : '')
                formData.append('notes', this.item.notes ?? '')
                if (this.formType == 'INSERT') {
                    this.filesUpload.forEach((x, i) => {
                        if (!x.id) {
                            formData.append('document', x.file)
                        }
                    })
                }
                else {
                    this.filesUpload.forEach((x, i) => {
                        if (x.file != undefined) {
                            formData.append('document', x.file)
                        }
                        else {
                            formData.append('actualfiles[' + i + '].fileid', x.file_id)
                            formData.append('actualfiles[' + i + '].filename', x.name)
                            formData.append('actualfiles[' + i + '].paperresponseid', x.paper_response_id)
                            formData.append('actualfiles[' + i + '].path', x.path)
                            formData.append('actualfiles[' + i + '].extension', x.extension)
                            formData.append('actualfiles[' + i + '].mimetype', x.type)
                            formData.append('actualfiles[' + i + '].signedurl', x.signed_url)
                        }
                    })
                    this.filesUploadOriginal.forEach((x, i) => {
                        formData.append('files[' + i + '].fileid', x.file_id)
                        formData.append('files[' + i + '].filename', x.name)
                        formData.append('files[' + i + '].paperresponseid', x.paper_response_id)
                        formData.append('files[' + i + '].path', x.path)
                        formData.append('files[' + i + '].extension', x.extension)
                        formData.append('files[' + i + '].mimetype', x.type)
                        formData.append('files[' + i + '].signedurl', x.signed_url)
                    })
                }
                formData.append('actions', JSON.stringify(this.actions))
                formData.append('idn', this.tagS)

                if (this.formType == 'INSERT') {
                    if (this.locationObj.length > 0) {
                        for (let i = 0; i < this.locationObj.length; i++) {
                            formData.append('location', this.locationObj[i])
                        }
                    }
                }
                else {

                    for (let i = 0; i < this.locationObj.length; i++) {
                        if (this.locationObj[i] != 0) {
                            formData.append('location', this.locationObj[i])
                        }
                    }
                }
                if (this.formType == 'INSERT') {
                    recallServices.createPaperResponse(formData).then(resp => {
                        if (!resp.error) {
                            this.$parent.fetchDataOnSuccess('Paper Response created successfully')
                            this.$refs.modal.hide()
                        }
                    })
                }
                else {
                    recallServices.updatePaperResponse(formData).then(resp => {
                        if (!resp.error) {
                            this.$parent.fetchDataOnSuccess('Paper Response updated successfully')
                            this.$refs.modal.hide()
                        }
                    })
                }
            }
        },
        setLocations() {
            this.locationObj = []
            this.locationListFiltered = []
            let idn = ''
            for (let x = 0; x < this.locationList.length; x++) {
                this.locationList[x].isSelected = false
                if (this.locationList[x].idn == this.tagS && (this.formType == 'INSERT')) {
                    this.locationListFiltered.push(this.locationList[x])
                }
                if (this.locationList[x].idn == this.tagS && (this.formType == 'EDIT')) {
                    if (!this.selectedLocations.includes(this.locationList[x].text) || this.valueTag.includes(this.locationList[x].text)) {
                        this.locationListFiltered.push(this.locationList[x])
                    }

                }
                if (this.valueTag.length > 0) {
                    for (let y = 0; y < this.valueTag.length; y++) {
                        if (this.locationList[x].text == this.valueTag[y]) {
                            if (this.formType == 'VIEW') {
                                this.locationListFiltered.push(this.locationList[x])
                            }
                            idn = this.locationList[x].idn
                            this.locationObj.push(this.locationList[x].value)
                            this.locationList[x].isSelected = true
                        }
                    }
                }
            }
        },
        responseDateEdit() {
            // var dateSubtract = moment()
            // dateSubtract = dateSubtract.subtract(-1,'DD')
            // alert('date: '+ moment(this.item.responseDate).format('YYYY/MM/DD'))
            this.responseDate = new Date()
        }

    }
}